export default {
  // general
  country: '居住国',
  state: '都道府県',
  city: '市区町村',
  postal_code: '郵便番号',
  address1: '住所 1',
  address2: '住所 2',
  expired: '有効期限は来月以降が必要です',
  invalid: '正しくありません',
  card_number_must_be_between_14_16_digit_number: '14-16桁の数字（0-9）を入力してください',
  card_number_allows_only_numeric_characters: '数字（0-9）での入力が必要です',
  name_of_card_too_long: '名称が長すぎます',
  name_of_card_allows_alpha_numeric_space:
    '英数字とスペース（a-z、A-Z、0-9、" "）での入力が必要です',
  desc_of_secvurity_allows_alpha_numeric_symbol:
    '英数字と一部記号（a-z、A-Z、0-9、. _-:/()#,@[]+=&;{}!$*）のみ入力可能です',
  license_instruction: '下記の不足しているライセンスを購入する必要があります',
  the_following_fees_will_be_charged_on_a_daily_basis_this_month:
    '今月は日割りで下記の料金が請求されます',
  network_will_remain_with_same_configuration_then_you_will_be_charged_monthly_fee_above:
    '来月も同じ構成でネットワークが存在する場合は、上記の月額料金が請求されます',
  required: 'この項目は必須です',
  invalid_email: '無効なメールアドレスです',
  too_many_character_error_message: 'ネットワーク名は{{ value }}文字以内にしてください',
  too_many_character_of_network_details_error_message: '詳細は{{ value }}文字以内にしてください',
  too_many_character_of_nodename_error_message: 'ノード名は{{ value }}文字以内にしてください',
  too_many_character_of_clustername_error_message:
    'クラスター名は{{ value }}文字以内にしてください',
  too_many_character_of_organization_name_error_message:
    '組織アカウント名は{{ value }}文字以内にしてください',
  too_many_character_of_profile_name_error_message:
    'アカウント名は{{ value }}文字以内にしてください',
  for: 'for',
  days: 'days',
  per_month: '月額',
  license_statement: 'ライセンスの購入',
  contract_detail: '契約内容',
  amount_billing: '金額 請求額',
  daily_rate_total: '日割合計',
  coupon_balance: 'クーポン残高',
  coupon_amount_used_this_time: '今回利用したクーポン金額',
  blockchain_network: '現在のネットワーク',
  select: '選択',
  nodes: 'ノード一覧',
  notifications: '通知',
  access: '権限',
  request: 'リクエスト',
  invitation: '招待',
  settings: '設定',
  transfer_role: '譲渡',
  charts: 'チャート',
  billing: '支払い',
  docs: 'ドキュメント',
  support: 'サポート',
  find_a_network: 'ネットワークを検索',
  new_network: '新しいネットワーク',
  profile_settings: 'プロファイル設定',
  organization_settings: '組織アカウント設定',
  logout: 'ログアウト',
  admin: '管理者',
  owner: 'オーナー',
  member: 'メンバー',
  guest: 'ゲスト',
  operator: 'オペレーター',
  alert_createAt: '{{date, YYYY/MM/DD - HH:mm:ss}}',
  user_createAt: '{{date, YYYY/MM/DD - HH:mm:ss}}',
  user_lastLoginAt: '{{date, YYYY/MM/DD - HH:mm:ss}}',
  approve: '承認',
  pending: '承認待ち',
  verified: '発行済み',
  failure: '失敗',
  accept: '承認',
  reject: '拒否',
  request_last_received_date: '{{date, YYYY/MM/DD - HH:mm:ss}}',
  request_last_sent_date: '{{date, YYYY/MM/DD - HH:mm:ss}}',
  general: '一般',
  contact: '連絡先',
  members: 'メンバー',
  organization_setting: '組織アカウント設定',
  save_change: '保存',
  updating: '更新中',
  processing: '処理中',
  plan: 'プラン',
  upgrade_plan: 'アップグレード',
  card_number: 'カード番号',
  change_credit_card: ' クレジットカードの変更',
  name: '名前',
  input_email_address: 'メールアドレスを入力',
  add_user: 'ユーザ追加',
  add_member: 'メンバー追加',
  member_name: 'メンバー名',
  cancel: 'キャンセル',
  submit: '送信',
  create: '作成',
  creating: '作成中',
  add_rule: 'ルール追加',
  email: 'メールアドレス',
  organization_role: '組織アカウントの権限',
  primary_owner: 'プライマリーオーナー',
  select_role: '権限選択',
  authorize_node: '承認されたノード',
  request_from: '招待元',
  node: 'ノード',
  proposal_type: 'プロポーザルタイプ',
  proposed_date_lbl: '日付',
  node_id: 'ノードID',
  organization: '組織アカウント',
  cluster_name: 'クラスター名',
  cluster_type: 'クラスタータイプ',
  signing_address: '署名アドレス',
  currency_name: '通貨名',
  currency_symbol: '通貨記号',
  currency_name_input: 'デフォルト: Ether',
  currency_symbol_input: 'デフォルト: ETH',
  too_many_currency_character_error_message: '{{ value }}文字まで入力してください。',
  attention_currency_updation: 'ブロックエクスプローラーを再構築して、通貨の変化を観察してください',
  enode: 'enode',
  server: 'サーバ',
  provider: 'プロバイダ',
  select_your_provider_first: 'プロバイダ選択が必要です',
  region: 'リージョン',
  select_your_region_first: 'リージョン選択が必要です',
  availability_zone: 'アベイラビリティゾーン',
  instance_type: 'インスタンスタイプ',
  block_explorer_instance_type: 'ブロックエクスプローラのインスタンスタイプ',
  join: 'Join',
  create_organization: '組織アカウント作成',
  please_enter_your_organization_name: '組織アカウント名を入力',
  proposal_already_exist: '既にプロポーザル送信中です',
  note: 'Note',
  enter_your_company_name_or_team_name_as_organization_name:
    '組織アカウント名を入力（会社名やチーム名を入力）',
  this_service_is_billed_per_organization: 'このサービスは組織アカウントごとに課金されます',
  organization_name: '組織アカウント名',
  input_organization_name: '組織アカウント名を入力',
  input_transfer_addr: '送金先アドレスを入力',
  input_accountant_email: '経理担当者のメールアドレスを入力',
  input_system_admin_email: 'システム管理者のメールアドレスを入力',
  transfer_addr: '送金先アドレス',
  create_cluster: 'クラスター作成',
  input_cluster_name: 'クラスター名を入力',
  select_cluster_type: 'クラスタータイプ選択',
  select_provider: 'プロバイダを選択',
  select_region: 'リージョンを選択',
  select_instance_type: 'インスタンスタイプ選択',
  select_block_explorer_instance_type: 'ブロックエクスプローラーインスタンスタイプの選択',
  number_of_nodes: 'ノード数',
  number_of_nodes_must_greater_or_equal_8: 'Block Intervalが5秒未満のときは8台以上必要です',
  input_number: 'ノード数を入力',
  input_gas_limit: 'Block Gas Limitを入力',
  input_block_interval: 'Block Intervalを入力',
  create_blockchain_network: 'ブロックチェーンネットワークの作成',
  advanced_menu: '詳細設定',
  network_name: 'ネットワーク名',
  is_create_block_explorer: 'BlockExplorer を作成',
  input_network_name: 'ネットワーク名を入力',
  initial_address: '初期アドレス (allocAddress)',
  input_initial_address: '初期アドレスを入力',
  input_network_desc: '説明を 1000 文字以内で入力',
  initial_balance: 'ETH 発行数 (allocBalance)',
  input_initial_balance: 'ETH 発行数を入力',
  block_interval: 'Block Interval (秒)',
  block_gas_limit: 'Block Gas Limit',
  gas_price: 'Gas Price',
  default_gas_price: 'デフォルト Gas Price',
  os_version: 'OS バージョン',
  select_os_version: 'OS バージョン選択',
  node_version: 'ノードのバージョン',
  select_node_version: 'ノードのバージョンを選択',
  garbage_collection_mode: 'ガベージ コレクション モード',
  select_garbage_collection_mode: 'ガベージ コレクション モードの選択',
  consensus_algorithm: '合意アルゴリズム',
  select_consensus_algorithm: '合意アルゴリズムを選択',
  create_node: 'ノード作成',
  create_block_explorer: 'Block Explorer 作成',
  node_name: 'ノード名',
  node_size: 'データボリュームサイズ',
  input_node_name: 'ノード名を入力',
  input_node_size: 'データボリュームサイズを指定',
  provider_name: 'プロバイダ',
  region_name: 'リージョン',
  delete_member: 'メンバー削除',
  user_name: 'ユーザ名',
  are_you_sure_you_want_to_delete_this_member: '本当にこのメンバーを削除してもよろしいでしょうか？',
  Are_you_sure_you_want_to_transfer_the_primary_owner:
    'プライマリーオーナーを譲渡してもよろしいでしょうか？',
  confirm: '確認',
  delete: '削除',
  deleting: '削除中',
  delete_cluster: 'クラスター削除',
  endpoint: 'エンドポイント',
  cluster_id: 'クラスターID',
  are_you_sure_you_want_to_delete_this_cluster:
    '本当にこのクラスターを削除してもよろしいでしょうか？',
  destroy_network: 'ネットワーク削除',
  network_id: 'ネットワークID',
  chain_id: 'チェーンID',
  number_of_cluster: 'クラスター数',
  are_you_sure_you_want_to_delete_this_network:
    '本当にこのネットワークを削除してもよろしいでしょうか？',
  destroy_network_dialog_input_placeholder: 'ネットワーク名を入力してください。',
  network_has_more_than_one_signer: '他のアカウントにValidatorが存在するため、削除できません。',
  removing_nodes_and_clusters: 'ノードとクラスターを削除中...',
  delete_node: 'ノード削除',
  are_you_sure_you_want_to_delete_this_node: '本当にこのノードを削除してもよろしいでしょうか？',
  delete_user: 'ユーザ削除',
  network_role: 'ネットワーク権限',
  edit_member: 'メンバー編集',
  update: '更新',
  edit_cluster: 'クラスター編集',
  edit_network: 'ネットワーク編集',
  blockchain_network_id: 'ネットワークID',
  edit_node: 'ノード編集',
  display_name: '表示名',
  input_display_name: '表示名を入力',
  language: '言語',
  select_language: '言語を選択',
  account: '組織アカウント',
  organizations_you_belong_to: '所属組織アカウント',
  network: 'ネットワーク',
  networks_you_have_control: 'ネットワーク',
  security: 'セキュリティ',
  log_out_of_all_sessions_except_this_current_browser: '現在のブラウザ以外のセッションをログアウト',
  log_out_other_sessions: '他のすべてのセッションをログアウト',
  password: 'パスワード',
  you_are_currently_logged_in_with_your_Google_account: '現在Googleアカウントでログインしています',
  change_account_password: 'パスワード変更',
  change_password: 'パスワード変更',
  edit_user: 'ユーザ編集',
  user_id: 'ユーザID',
  input_user_id: 'ユーザIDを入力',
  input_user_name: 'ユーザ名を入力',
  grant_access: '権限付与',
  grant: '付与',
  granting: '権限付与中',
  there_is_a_notification: 'お知らせがあります',
  there_is_a_node_of_waiting_for_approval: '新しい通知があります',
  see_all: 'すべて見る',
  add_new_organization: '組織アカウントを追加',
  organizations: '組織アカウント',
  organization_count: '{{count}}組織アカウント',
  organization_count_plural: '{{count}}組織アカウント',
  you: 'You',
  invite_network: '他の組織アカウントを招待',
  send: '送信',
  sending: '送信中',
  organization_id: '組織アカウントID',
  email_address: 'メールアドレス',
  find_a_user: 'ユーザを検索',
  role: '権限',
  create_date: '作成日',
  last_login: 'ラストログイン',
  actions: '操作',
  find_notification: '通知を検索',
  current_display: 'Current Display',
  type: 'タイプ',
  size: 'サイズ',
  quantity: '数量',
  month_current: '今月',
  month_next: '来月',
  title: 'タイトル',
  date: '日付',
  amount: '数量',
  invoice_no: 'インボイス登録番号',
  important: '重要',
  information: '情報',
  warning: '警告',
  error: 'エラー',
  help: 'ヘルプ',
  cluster_information: 'クラスター情報',
  signer_node_count: 'Validator ノード数',
  transaction_node_count: 'Relay ノード数',
  recent_event: '最近のイベント',
  description: '詳細',
  states: 'ステータス',
  blockchain_id: 'ブロックチェーンID',
  node_type: 'ノードタイプ',
  overview: '概要',
  accepted: '承認',
  rejected: '拒否',
  cancelled: 'キャンセル',
  received: '受信',
  sent: '送信',
  action: '操作',
  get_proposals: '提案を受ける',
  getting: '取得',
  from_coin_base_address: 'proposer ノードのアドレス',
  from_node_uuid: 'proposer ノード ID',
  to_coin_base_address: 'target ノードのアドレス',
  to_node_uuid: 'target ノード ID',
  propose_state: 'ステータス',
  invite: '招待',
  average_block_time: '平均ブロック間隔',
  total_transactions: '総トランザクション数',
  wallet_addresses: '発行アドレス数',
  total_blocks: '総ブロック数',
  network_information: 'ネットワーク情報',
  network_uuid: 'ネットワーク Uuid',
  number_of_nodes_clusters: 'ノード/クラスター',
  others_organization_s_cluster: '他組織アカウントのクラスター',
  metrics: '統計情報',
  block_explorer: 'ブロックエクスプローラ',
  node_count: 'ノード数',
  geth_RPC_endpoint: 'Geth RPC エンドポイント',
  block_explorer_URL: 'ブロックエクスプローラURL',
  approved: '承認',
  create_new_blockchain_network: '新しいブロックチェーンネットワークの作成',
  ip_address: 'IPアドレス',
  public_DNS_name: 'Public DNS name',
  node_detail_page_proposer_description:
    'ノードタイプを変更するには、プロポーザルを送信してください',
  send_proposal: 'プロポーザル送信',
  approval: '承認',
  page_not_found: 'ページが見つかりません',
  find_a_node: 'ノード検索',
  cluster: 'クラスター',
  sender: '送信側',
  receiver_you: '受信側 (自組織アカウント)',
  request_types: 'プロポーザルタイプ',
  last_received_date: '最後に受信した日',
  signer: 'Signer',
  transaction: 'Transaction',
  sender_you: '送信側 (自組織アカウント)',
  receiver: '受信側',
  last_sent_date: '最後に送信した日時',
  appropval_status: '承認ステータス',
  proposals: 'プロポーザル',
  approve_all: 'すべて承認',
  reject_all: 'すべて拒否',
  my_cluster: '自組織アカウントのクラスター',
  edit: '編集',
  proposed_date: '{{date, YYYY/MM/DD - HH:mm:ss}}',
  status: 'Status',
  approved_nodes: '承認ノード',
  rejected_nodes: '拒否ノード',
  not_respond_nodes: '未到達ノード',
  num_of_signers: 'Validatorノード数',
  link: 'link',
  error_alloc_address: '40桁の16進数（a-f、0-9）が必要です。',
  error_hexadecimal_number_required: '16進数（a-f、A-F、0-9）での入力が必要です。',
  error_zero_balance_not_allowed: '初期値は0以上である必要があります',
  required_field: 'この項目は必須です',
  integer_field: '整数値を入力してください',
  input_organization_id: '組織IDを入力してください',
  invitation_update_at: '{{date, YYYY/MM/DD - HH:mm:ss}}',
  proposing: '提案中',
  proposed: '投票済',
  sent_approval: '送信承認',
  sent_rejection: '送信拒否',
  must_be_greater_than_current_size:
    '現在のデータボリュームサイズより大きいサイズを指定してください',
  must_be_less_than_or_equal_to_16tb: '16TB以上は指定できません',
  possible_zero_signer_error: 'Validator ノード数が 0 になる可能性のある proposal は送信できません',
  force_delete_message:
    '強制的にネットワークを削除するには、確認のためネットワーク名を入力してください。',
  applying: '反映中',
  payment_way: '決済手段',
  license: 'ライセンス',
  bill_state: '課金明細',
  register: '登録',
  purchase: '購入',
  purchasing: '購入中',
  reboot: '再起動',
  forceReboot: '強制再起動',
  rebuild: '再構築',
  stop: '停止',
  start: '起動',
  port_range: 'ポート範囲',
  protocol: 'プロトコル',
  source: 'アクセス元',
  deleted: '削除されました',
  register_payment_method: '支払い方法を登録',
  the_following_cards_can_be_used: '下記のカードが利用できます。',
  register_your_card: '登録',
  name_on_card: 'カード名義人',
  expiration_date: '有効期限',
  cvc_number: 'CVC番号',
  expand: 'データボリュームサイズを拡張',
  transfer_primary_owner: 'プライマリーオーナーを譲渡',
  edit_inbound_rules: 'インバウンドルールを編集',
  edit_rules: 'ルールの編集',
  can_not_get_inbound_rules:
    'インスタンス作成中のためインバウンド通信の許可リストを取得できません。',
  no_active_licenses: 'アクティブなライセンスはありません',
  usage_history: '使用履歴',
  billing_month: '請求月',
  payment_status: '支払い状況',
  billing_amount: '請求金額',
  billing_date: '請求日',
  billing_date_value: '{{date, YYYY/MM/DD}}',
  billing_id: '請求ID',
  open: '詳細',
  paid: '支払済み',
  unpaid: '未払い',
  unpaid_exist: '未払い有り',
  payment_failed: '決済失敗',
  license_item_id: 'ライセンスID',
  license_name: 'ライセンス名',
  unit_price: '単価',
  subtotal: '小計',
  total: '合計',
  tax: '消費税',
  amount_of_coupon_used: 'クーポン利用額',
  debug: 'デバッグ',
  mark_all_read: 'すべて既読にする',
  withdraw: '退会',
  option: 'オプション',
  deadlock_resolver: 'デッドロック・リゾルバ',
  please_input_organization_name_exactly_for_confirmation:
    '確認のため組織アカウント名を入力してください。',
  are_you_sure_you_want_to_withdraw_even_if_you_have_available_licenses:
    '利用可能なライセンスが残っていますが、本当に退会しますか？',
  used: '使用中',
  node_disk_size: 'ディスク容量', //unused
  node_disk_used: 'ディスク使用量', //unused
  volume_size: 'Data Volume',
  data_volume_size: 'データボリュームサイズ',
  payment_address: '請求先住所',
  resource: 'リソース',
  cpu_usage: 'CPU使用率',
  ram_usage: 'メモリ使用率',
  swap_usage: 'スワップ使用率',
  peer: 'Peer',
  peer_count: 'Peer数',
  logs: 'ログ',
  log_file: 'ログファイル',
  log_messages: 'ログ内容',
  x_warn_disk: 'データボリューム容量不足',
  can_not_get_log: 'ログを取得できません。インスタンス作成中かログがまだ生成されていません。',
  warn_create_network_nodes_value:
    'ノード数を8台未満で稼働するとブロックチェーンが停止する確率が高まります。\n実行してよろしいですか？',
  validate_minimum: '{{ val }} 以上を入力してください',
  validate_maximum: '{{ val }} 以下で入力してください',
  validate_maximum_length: '{{ val }} 文字以下で入力してください',
  validate_length_100: 'テキストは 100 文字以下で入力してください',
  validate_account_not_matched: 'オーナーのみ許可された操作です',
  validate_account_has_no_addr: '組織アカウントに送金先のアドレスが指定されていません',
  monitoring: 'モニタリング',
  time_range: '期間 :',
  metrics_range_option_label_last_hour: '過去{{ value }}時間',
  metrics_range_option_label_last_hour_plural: '過去{{ value }}時間',
  metrics_range_option_label_last_day: '過去{{ value }}日',
  metrics_range_option_label_last_day_plural: '過去{{ value }}日間',
  metrics_range_option_label_last_week: '過去{{ value }}週間',
  metrics_range_option_label_last_week_plural: '過去{{ value }}週間',
  metrics_period_option_label_minute: '{{ value }}分',
  metrics_period_option_label_minute_plural: '{{ value }}分',
  metrics_period_option_label_hour: '{{ value }}時間',
  metrics_period_option_label_hour_plural: '{{ value }}時間',
  metrics_period_option_label_day: '{{ value }}日',
  metrics_period_option_label_day_plural: '{{ value }}日',
  period: '間隔 :',
  disk_capacity: 'ディスク容量', //unuse
  disk_usage: 'ディスク使用量', //unuse
  disk_data: 'データディスク', //unuse
  system_volume: 'System Volume',
  memory: 'Memory',
  cpu: 'CPU',
  cpu_usage_pc: 'CPU使用率 (%)',
  ram_usage_pc: 'メモリ使用率 (%)',
  swap_usage_pc: 'スワップ使用率 (%)',
  disk_usage_pc: 'データディスク (%)', //unuse
  volume_usage_pc: 'データボリューム使用率 (%)',
  delete_organization: '退会',
  are_you_sure_you_want_to_delete_organization:
    '本当に組織アカウントを削除してもよろしいでしょうか？',
  confirm_delete_organization_guide:
    '組織アカウントを削除するためには、組織アカウント名を以下のフィールドに入力して下さい。',
  execution_failed_please_try_again_later: '実行に失敗しました。時間をおいてお試しください。',
  all_services_will_not_be_available_after_withdrawal:
    '退会後は全てのサービスが利用できなくなります。',
  organization_setting_withdrawal_notice:
    'Notice: 組織アカウントを削除するには、組織アカウントのユーザがOwner以外削除され、かつ組織アカウント配下のすべてのネットワークが削除されており、未払いなどが存在しない必要があります。',
  account_name_not_matched: 'アカウント名が一致しません。',
  download_full_log: 'すべてのログをダウンロード',
  lines: '行',
  get_log: 'ログを取得',
  expires: '有効期限',
  standard_plan: 'スタンダードプラン',
  active: 'アクティブ',
  your_credit_cards: 'クレジットカード',
  coupons: 'クーポン',
  granted_date: '付与日',
  coupon_name: 'クーポン名',
  expire_date: '有効期限',
  invalid_cluster_type: 'クラスタータイプが正しくありません',
  cluster_type_not_allowed_this_instance: 'このクラスタータイプでは使用できません',
  cluster_type_not_allowed_this_nums: 'クラスタータイプに必要な台数未満です',
  cluster_not_selected: 'クラスターが選択されていません',
  desc_cluster_small: '実験用途や低トラフィックで小規模なプロダクション用途に最適なクラスタです。',
  desc_cluster_medium: '平均10-100tpsの中規模な利用に最適です。',
  desc_cluster_large: '平均100tps以上の大規模な利用に最適です。',
  desc_cluster_mega: '平均100tps以上のさらに大規模な利用に最適です。',
  desc_cluster_enterprise: 'Enterprise契約向けのカスタム可能なクラスタです。',
  balance: 'イーサリアム残高',
  transfer: '残高を送金',
  lack_of_balance_to_transfer: '送金手数料を含めた残高が不足しているため送金できません。',
  invalid_hex_address_format: 'アドレスは 0x で始まる42桁の16進数で入力してください',
  confirm_send_ether:
    'ノードに蓄積されたイーサリアムを組織アカウント指定のアドレスに送金します。\n本当に実行してよろしいですか？',
  an_error_occured: '処理中にエラーが発生しました',
  language_not_exist: '言語が存在しません',
  account_not_exist: '組織アカウントが存在しません',
  account_not_selected: '組織アカウントが選択されていません',
  account_not_attached_to_aws:
    'この組織アカウントはエンタープライズプランを利用中ですが AWS に関連付けられていません',
  user_not_exist: 'ユーザー情報が存在しません',
  coupon_not_exist: 'クーポン情報が存在しません',
  address_not_registered: '住所が未登録です',
  aws_account_not_exist: 'AWS アカウントが存在しません',
  aws_account_not_available: '利用可能な AWS アカウントがありません',
  aws_account_creation_failed: 'AWS アカウントの作成に失敗しました',
  invitation_already_exist: 'すでに招待済みです。',
  invitation_table_account_received_from: '組織アカウント（受信元）',
  invitation_table_account_sent_to: '組織アカウント（送信先）',
  the_member_already_exists: 'そのメンバーはすでに存在します',
  invalid_country_code: '居住国の指定が正しくありません',
  invalid_postal_code: '半角数字で「000-0000」、または、「0000000」の形式で入力してください。',
  can_not_register_account_address: '住所を登録できませんでした',
  can_not_update_account_contact: '連絡差を登録できませんでした',
  update_account_address_success: '住所の登録が完了しました',
  update_account_contact_success: '連絡先の更新が完了しました',
  billing_data_corrupted: '課金データが不整合のため集計できませんでした',
  can_not_get_network: 'ネットワーク情報を取得できませんでした',
  can_not_get_node: 'ノード情報を取得できませんでした',
  can_not_get_cluster: 'クラスター情報を取得できませんでした',
  can_not_get_proposal: 'プロポーザル情報を取得できませんでした',
  can_not_get_provider: 'プロバイダ情報を取得できませんでした',
  the_network_is_still_in_the_process_of_construction: 'ネットワーク作成中のため操作できません',
  the_network_is_already_in_the_process_of_destruction: 'ネットワーク削除中のため操作できません',
  the_cluster_is_already_in_the_process_of_destruction_or_construction:
    'クラスターの作成中または削除中につき操作できません',
  the_node_is_already_in_the_process_of_destruction_or_construction:
    'ノードの作成中または削除中につき操作できません',
  the_block_explorer_is_already_in_the_process_of_destruction_or_construction:
    'Block Explorer の作成中または削除中につき操作できません',
  the_node_has_an_inoperable_status: 'このノードは操作できない状態にあります',
  the_block_explorer_has_an_inoperable_status: 'この Block Explorer は操作できない状態にあります',
  the_network_still_has_more_than_1_validator_belong_other_account:
    'ネットワークの Validator ノードを他のアカウントが管理しているため削除できません',
  the_network_still_has_more_than_1_validator_node:
    'ネットワークに2つ以上の Validator ノードがあるため削除できません',
  the_cluster_still_has_more_than_1_validator_node:
    'クラスターに Validator ノードがあるため削除できません',
  The_rule_has_already_been_registered_or_same_rule_is_specified:
    '指定されたルールは登録済、または同じルールが指定されました',
  can_not_delete_the_last_cluster: 'ネットワークで唯一のクラスターにつき削除できません',
  can_not_delete_the_last_node: 'クラスターで唯一のノードにつき削除できません',
  the_cluster_has_all_of_validator_nodes_of_network:
    'Validator ノードを持つ唯一のクラスターにつき削除できません',
  you_can_not_delete_a_validator_node:
    'Validator ノードは削除できません。Relayノードに変更してから削除してください',
  you_dont_have_a_permission_to_perform_the_account: '組織アカウントを操作する権限がありません',
  you_dont_have_a_permission_to_perform_the_cluster:
    '他の組織アカウントが所有するクラスターを操作する権限がありません',
  you_dont_have_a_permission_to_add_a_member_to_account:
    '組織アカウントにメンバーを追加する権限がありません',
  you_dont_have_a_permission_to_grant_an_access_role:
    '組織アカウントメンバーのネットワーク権限を操作する権限がありません',
  you_dont_have_a_permission_to_create_network_in_this_account:
    'この組織アカウントを操作する権限がないためネットワークを作成できません',
  you_dont_have_a_permission_to_create_a_cluster_in_this_network:
    'このネットワークを操作する権限がないためクラスターを作成できません',
  you_dont_have_a_permission_to_create_a_node_in_this_cluster:
    'このクラスターを操作する権限がないためノードを作成できません',
  you_dont_have_a_permission_to_cancel_the_proposal:
    'このプロポーザルをキャンセルする権限がありません',
  you_dont_have_a_permission_to_send_ether: 'Etherを送信する操作権限がありません',
  you_dont_have_a_permission_to_delete_the_node: 'ノードを削除する権限がありません。',
  fail_to_get_account_uuid: '組織アカウントUUIDを新たに生成できません',
  fail_to_get_network_id: 'ネットワークIDを新たに生成できません',
  account_does_not_exist: '組織アカウントが存在しません',
  member_does_not_exist_in_account: '組織アカウント内にユーザーが存在しません',
  member_already_exist: 'ユーザーは既に登録されています',
  member_does_not_exist: 'ユーザーが存在しません',
  there_is_only_one_owner_can_not_revoke_owner: '唯一のOwnerであるため変更できません',
  there_is_owner_can_not_revoke_owner: 'Primary Ownerであるため変更できません',
  members_who_are_not_owners_cannot_change_to_primary_owners:
    'オーナー以外のメンバーにプライマリーオーナーを譲渡できません',
  user_still_have_more_than_1_network_owner_role:
    '所有者に設定されているネットワークが1つ以上あります',
  user_is_the_last_owner_of_a_network:
    '唯一の所有者となっているネットワークが存在するため削除できません',
  can_not_revoke_owner_final: '唯一の所有者ユーザーであるため削除できません',
  cant_not_revoke_last_owner: '最後の所有者を取り消すことはできません',
  can_not_revoke_primary_owner: 'プライマリーオーナーであるため削除できません',
  geth_rpc_endpoint_notification:
    'Geth RPC Endpointを利用するには、ポートをオープンにしてください。',
  geth_rpc_endpoint_notification_for_member:
    'Geth RPC Endpointを利用するには、権限ユーザーでポートをオープンにしてください。',
  add_inbound_rules: 'インバウンドルールの追加',
  node_geth_did_not_up_after_reboot: 'ノードサーバの再起動後、gethサービスが起動しませんでした',
  block_explorer_http_did_not_up_after_reboot:
    'Block Explorerサーバの再起動後、Block Explorerサービスが起動しませんでした',
  restartForce_command_sent: '強制再起動コマンドが送信されました',
  stop_command_sent: '停止コマンドが送信されました',
  start_command_sent: '開始コマンドが送信されました',
  reboot_command_sent: '再起動コマンド送信完了しました',
  rebuild_command_sent: '再構築コマンド送信完了しました',
  maximum_modification_rate_per_volume_limit_reached:
    'データボリュームサイズの拡張は前回の拡張から6時間が経過しないとできません',
  the_amount_is_not_enough_for_the_minimum_settlement_amount:
    '税別の小計が0.5USDに満たない場合は決済できません。',
  the_node_to_be_proposed_to: '提案中のノードです',
  billing_address_dialog_title: '請求先住所',
  billing_address_dialog_description: '課金のための請求先住所を入力して下さい。',
  billing_address_dialog_country_label: '国',
  billing_address_dialog_postcode_label: '郵便番号',
  billing_address_dialog_postcode_placeholder: '123-4567',
  billing_address_dialog_address_label: '住所',
  billing_address_dialog_state_placeholder: '都道府県',
  billing_address_dialog_state_required_error_text: '都道府県を入力してください',
  billing_address_dialog_city_placeholder: '市区町村',
  billing_address_dialog_city_required_error_text: '市区町村を入力してください',
  billing_address_dialog_address_1_required_error_text: '住所（1行目）を入力してください',
  billing_address_dialog_address_1_placeholder: '住所（1行目）',
  billing_address_dialog_address_2_placeholder: '住所（2行目）',
  trailing_spaces_not_allowed_to_name: '名前に空白文字列は使えません',
  invalid_protocol_type: 'プロトコルが正しくありません',
  invalid_ipv4_cidr_format:
    '入力形式 (IPv4) が正しくありません。 CIDR (IPv4) のフォーマット（x.x.x.x/32）で入力してください。',
  error_my_ip_address: 'IPアドレスの取得に失敗しました。手動でIPアドレスを入力してください。',
  amount_must_be_greater_than_zero: '金額は 0 より大きい数字を指定してください',
  invalid_date_format: '日付が正しくありません',
  failed_to_update_payment_service_name: '決済サービスの名前の更新に失敗しました',
  failed_to_update_payment_service_email: '決済サービスのメールアドレスの更新に失敗しました',
  failed_to_update_payment_service_language: '決済サービスの言語設定ができません',
  side_bar_payment_alert_title: 'お支払い情報の確認',
  side_bar_payment_alert_content:
    'お支払い情報の登録がされていません。引き続きサービスをご利用いただくには、お支払い情報をご登録ください。',
  side_bar_payment_alert_button_label: '支払情報を登録',
  can_not_look_up_dns: 'DNS情報の検索ができませんでした',
  can_not_revoke_yourself: '自ユーザーのネットワーク権限は操作できません',
  can_not_grant_yourself: '自分自身を付与することはできません。', // TODO: Need to translate
  network_create_fail_block_or_dns: 'ブロックの生成が確認できません',
  specified_email_not_registered_in_system:
    '指定されたメールアドレスがこのシステムには登録されていません',
  terms_of_service_header_title: 'ようこそ、G.U.Blockchain Cloudへ。',
  terms_of_service_checkbox_label: '利用規約に同意します',
  terms_of_service_disagree_button_label: '同意しない',
  terms_of_service_agree_button_label: '同意して始める',
  billing_details_tab_title: '請求住所',
  billing_details_tab_country: '国',
  billing_details_tab_postcode: '郵便番号',
  billing_details_tab_address: '住所',
  sign_in: 'ログイン',
  you_have_reached_the_creation_limit: 'あなたは組織アカウントの作成上限に達しました。',
  node_information: 'ノード情報',
  server_information: 'サーバー情報',
  block_explorer_information: '情報',
  volume_size_less_than_the_minimum_limitation:
    'データボリュームサイズが指定可能な最小値より小さく入力されています',
  volume_size_exceeded_maximum_limitation:
    'データボリュームサイズが指定可能な最大値より大きく入力されています',
  unmatch_storage_and_license_count: 'データボリュームサイズとライセンスの数が一致しません',
  invalid_node_license_calculation: 'ライセンスの計算に失敗しました',
  can_not_control_node: 'ノードの変更に失敗しました',
  can_not_control_block_explorer: 'Block Explorer の変更に失敗しました',
  can_not_expand_node_volume_size: 'ノードのデータボリュームサイズの拡張に失敗しました',
  can_not_expand_block_explorer_volume_size:
    'Block Explorer のデータボリュームサイズの拡張に失敗しました',
  the_network_has_resource_which_in_process_of_destruction_or_construction:
    '起動中や削除中など状態変更中のクラスターまたはノードがあるため、操作できません。',
  edit_network_dialog_description_placeholder: '{{ value }}文字以内の説明',
  billing_payment_tab_granted_date: '{{date, YYYY/MM/DD}}',
  billing_payment_tab_expried_date: '{{date, YYYY/MM/DD}}',
  unknown_payment_error: '決済処理でエラーが発生しました',
  can_not_find_out_stripe_customer: '決済サービスのユーザー情報が取得できませんでした',
  stripe_customer_is_deleted: '決済サービスのユーザー情報は削除されています',
  can_not_get_valid_card_expiration: '決済サービスから有効なカード期限が取得できませんでした',
  unknown_log_type_specified: '不明なログタイプです',
  unable_to_send_ether_because_node_is_down: 'ノードが停止中のため送金できません',
  network_does_not_belong_to_account: 'このネットワークは所属するアカウントがありません',
  network_user_does_not_exist: 'ネットワークユーザーが存在しません',
  this_function_is_only_available_in_enterprise_plans:
    'この機能はエンタープライズプランのみ利用できます',
  run: '実行',
  running: 'ランニング',
  owner_email: 'オーナーメールアドレス',
  consortium: 'コンソーシアム',
  edit_role: '権限を編集',
  withdraw_from_network: 'ネットワークから退会',
  withdraw_consortium_warning_text: '本当にこのネットワークから退会してもよろしいでしょうか？',
  signer_node: 'Validatorノード',
  transaction_node: 'Relayノード',
  consortium_admin: 'コンソーシアム管理者',
  consortium_member: 'コンソーシアムメンバー',
  // ----------
  //
  // stripe error codes (see https://stripe.com/docs/error-codes)
  //
  account_already_exists: 'アカウントが既に存在します',
  account_country_invalid_address: 'アカウントの国籍と居住国が異なります',
  account_invalid: 'アカウントが不正です',
  account_number_invalid: '口座番号が不正です',
  alipay_upgrade_required: 'Alipay のアップデートが必要です',
  amount_too_large: '金額が多すぎます',
  amount_too_small: '金額が少なすぎます',
  api_key_expired: 'APIキーが失効しています',
  balance_insufficient: '残高不足です',
  bank_account_exists: '銀行口座がすでに存在します',
  bank_account_unusable: 'この銀行口座に振り込むことができません。他の口座を入力してください',
  bank_account_unverified: 'この口座はまだ承認されていません',
  bitcoin_upgrade_required: 'ビットコインのアップデートが必要です',
  card_declined: 'このカードはご利用できません',
  charge_already_captured: 'この決済はすでにキャプチャ済みです',
  charge_already_refunded: 'この決済はすでに返金済みです',
  charge_disputed: 'この決済はチャージバック中です',
  charge_exceeds_source_limit: 'この決済は上限を超過しています',
  charge_expired_for_capture: 'この決済はキャプチャ期間を過ぎています',
  country_unsupported: '指定された国ではサポートされていません',
  coupon_expired: 'クーポンが失効しています',
  customer_max_subscriptions: 'サブスクリプションの上限を超過しています',
  email_invalid: 'Emailが不正です',
  expired_card: 'カードの有効期限が失効しています',
  idempotency_key_in_use: '現在、処理が混み合っています。しばらくしてから再度処理を行ってください',
  incorrect_address: 'カードの住所情報が誤っています。再度入力するか他のカードをご利用ください',
  incorrect_cvc:
    'カード裏面のセキュリティーコードが誤っています。再度入力するか他のカードをご利用ください',
  incorrect_number: 'カード番号が誤っています。再度入力するか他のカードをご利用ください',
  incorrect_zip: 'カードの郵便番号が誤っています。再度入力するか他のカードをご利用ください',
  instant_payouts_unsupported:
    'このデビットカードは即入金に対応していません。他のカードをご利用いただくか銀行口座を入力してください',
  invalid_card_type:
    '対応していないカードタイプです。他のカードをご利用いただくか銀行口座を入力してください',
  invalid_charge_amount: '不正な金額です',
  invalid_cvc: 'カード裏面のセキュリティーコードが誤っています',
  invalid_expiry_month: 'カードの有効期限(月)が誤っています',
  invalid_expiry_year: 'カードの有効期限(年)が誤っています',
  invalid_number: 'カード番号が不正です。再度入力するか、他のカードをご利用ください',
  invalid_source_usage: '不正な支払いソースです',
  invoice_no_customer_line_items: '請求書が存在しません',
  invoice_no_subscription_line_items: '請求書が存在しません',
  invoice_not_editable: 'この請求書は書き換え不可です',
  invoice_upcoming_none: '請求書が存在しません',
  livemode_mismatch: 'APIキーが不正です',
  missing: '支払い情報のリンクに失敗しました',
  order_creation_failed:
    '注文が失敗しました。注文を再度確認するか、しばらくしてから再度処理を行ってください',
  order_required_settings: '情報に不足があるため、注文に失敗しました',
  order_status_invalid: '注文状態が不正なため、更新できません',
  order_upstream_timeout: '注文がタイムアウトしました。しばらくしてから再度処理を行ってください',
  out_of_inventory: '在庫が無いため注文できません',
  parameter_invalid_empty: '情報が不足しています',
  parameter_invalid_integer: '不正な整数値です',
  parameter_invalid_string_blank: '空白文字エラーです',
  parameter_invalid_string_empty: '少なくとも1文字以上を入力してください',
  parameter_missing: '情報が不足しています',
  parameter_unknown: '不正なパラメータが存在します',
  payment_method_unactivated: '支払い方法がアクティベートされていないため、決済に失敗しました',
  payouts_not_allowed: 'このアカウントに入金できません。状態を確認してください',
  platform_api_key_expired: 'プラットフォームAPIキーが失効しています',
  postal_code_invalid: '郵便番号が不正です',
  processing_error: '処理中にエラーが発生しました。再度入力するか、他のカードをご利用ください',
  product_inactive: 'この商品は現在取り扱いをしていません',
  rate_limit: 'API上限を超過しました',
  resource_already_exists: 'リソースがすでに存在します',
  resource_missing: 'リソースが存在しません',
  routing_number_invalid: '口座番号・支店番号が誤っています',
  secret_key_required: 'シークレットキーが存在しません',
  sepa_unsupported_account: 'このアカウントはSEPAに対応していません',
  shipping_calculation_failed: '送料計算に失敗しました',
  sku_inactive: 'SKU に対応していません',
  state_unsupported: 'この州には現在対応していません',
  tax_id_invalid: 'TAX IDが不正です。少なくとも 9 桁入力する必要があります',
  taxes_calculation_failed: '税金計算に失敗しました',
  testmode_charges_only: 'テストモードの決済限定です',
  tls_version_unsupported: 'このTLSのバージョンに対応していません',
  token_already_used: 'このトークンはすでに使用済みです',
  token_in_use: 'このトークンは現在使用中です',
  transfers_not_allowed: '現在、送金が行えません',
  upstream_order_creation_failed:
    '注文に失敗しました。注文を再度確認するか、しばらくしてから再度処理を行ってください',
  url_invalid: 'URLが不正です',
  start_month: '開始月',
  end_month: '終了月',
  to: '〜',
  start_date: '開始日',
  end_date: '終了日',
  date_format: 'YYYY/MM/DD',
  can_not_get_vpc: 'VPC情報が取得できません',
  timezone: 'タイムゾーン',
  select_timezone: 'タイムゾーンを選択',
  invalid_timezone_type: 'タイムゾーンの指定が正しくありません',
  invalid_language_type: '言語の指定が正しくありません',
  invalid_intake_host_configuration: 'DataDog ログ送信先の設定に問題があります',
  error_connecting_to_dataDog: 'DataDog ログ送信処理に失敗しました',
  no_token_provided: '認証トークンが指定されていません',
  session_cookie_does_not_set: '認証クッキーが見つかりません',
  you_cant_change_the_plan_the_account_select_same_plan:
    'エンタープライズプランを利用しているか、または既にパーソナルプランであるため利用プランを変更できません',
  invalid_email_address_format: 'メールアドレスの書式が正しくありません',
  can_not_find_out_administrator: '管理者ユーザーが見つかりません',
  can_not_update_Profile: 'プロファイルを更新できません',
  can_not_get_list_member: 'メンバーリストを取得できません',
  can_not_add_member: 'メンバーを追加できません',
  can_not_list_members: 'メンバーリストが表示できません', // TODO: Need to translate
  can_not_remove_member: 'メンバーを削除できません',
  can_not_change_primary_owner: 'プライマリーオーナーを譲渡できません',
  can_not_create_account: '組織アカウントを作成できません',
  can_not_update_account: '組織アカウントを更新できません',
  can_not_remove_account: '組織アカウントを削除できません',
  can_not_get_list_network: 'ネットワーク一覧を取得できません',
  can_not_create_network: 'ネットワークを作成できません',
  can_not_update_network_information: 'ネットワーク情報を更新できません',
  can_not_update_network: 'ネットワーク情報を更新できません',
  can_not_destroy_network: 'ネットワークを削除できません',
  can_not_create_cluster: 'クラスターを作成できません',
  can_not_update_cluster: 'クラスター情報を更新できません',
  can_not_create_node: 'ノードを作成できません',
  can_not_get_list_network_accounts: 'ネットワークに関連付けられたアカウント一覧を取得できません',
  can_not_find_out_invoice: '請求情報が見つかりません',
  can_not_find_out_cluster: 'クラスターが見つかりません',
  can_not_find_out_node: 'ノードが見つかりません',
  can_not_register_address: '住所情報を登録できません',
  can_not_update_contact: '連絡先情報を更新できません',
  account_is_not_exist: '組織アカウントが見つかりません',
  can_not_find_out_network: 'ネットワークが見つかりません',
  can_not_find_out_cluster_to_update: '更新するクラスターが見つかりません',
  can_not_find_out_network_to_delete: '削除するネットワークが見つかりません',
  can_not_find_out_cluster_to_delete: '削除するクラスターが見つかりません',
  can_not_add_node: 'ノードを追加で来ません',
  can_not_find_out_network_to_update: '更新するネットワークが見つかりません',
  can_not_find_out_node_to_update: '更新するノードが見つかりません',
  can_not_update_node: 'ノード情報を更新できません',
  can_not_find_out_node_to_delete: '削除するノードが見つかりません',
  network_does_not_exist: 'ネットワークが見つかりません',
  sender_account_does_not_exist: '送信元アカウントが見つかりません',
  receiver_account_does_not_exist: '宛先アカウントが見つかりません',
  target_node_does_not_exist: '対象ノードが見つかりません',
  user_profile_does_not_exist: 'ユーザーのプロファイルが見つかりません',
  cannot_save_network_because_user_profile_does_not_exist:
    '選択されているネットワークの変更に失敗しました',
  cannot_save_user_profile_because_user_profile_does_not_exist: 'プロファイルの更新に失敗しました',
  admin_profile_does_not_exist: '管理者のプロファイルが見つかりません',
  node_does_not_exist: 'ノードが見つかりません',
  account_UUID_does_not_exist: 'ログインユーザーが選択中の組織アカウントが見つかりません',
  proposed_node_does_not_exist: '提案対象のノードが見つかりません',
  network_already_exist_in_account: 'ネットワークは既にアカウントと関連付けられています',
  invitation_does_not_exist: '招待が見つかりません',
  receiver_account_was_consortium_already:
    'このアカウントはすでにコンソーシアムに招待されています。',
  update_consortium_role_success: 'コンソーシアム権限の更新が完了しました',
  withdraw_consortium_success: 'コンソーシアムの退会が完了しました',
  consortium_does_not_exist: 'コンソーシアムが存在しません。',
  cannot_remove_last_consortium_admin: '最後のコンソーシアム管理者は削除できません。',
  invitation_was_already_responsed: '招待に返信済みです',
  invitation_was_canceled: '招待はキャンセル済みです',
  query_result_is_empty: '検索結果がありません',
  alert_does_not_exist: '通知が見つかりません',
  alert_already_set_read: '通知は既に既読となっています',
  possible_0_validator_node_can_not_send_proposal:
    'ネットワークから Validator ノードがなくなる可能性があるため提案を送信できません',
  admin_does_not_exist: '管理者ユーザーが見つかりません',
  failed_to_finded_user_profile_of_specified_uid: 'ユーザーのプロファイルが見つかりません',
  add_peer_failed: 'ブロックチェーンネットワークのピアリングに失敗しました',
  ssh_prepare_failed: 'ノードへのSSH接続の準備でエラーが発生しました',
  language_of_notification_emails: '通知メールの言語',
  accountant_Email: '経理担当者メールアドレス',
  system_admin_Email: 'システム管理者メールアドレス',
  withdrawn: '退会済み',
  can_not_check_block_number: '最新のブロック番号が指定の値以上になったことを確認できません',
  the_list_of_validators_is_not_synced: 'Validator の一覧が同期できていないため処理できません',
  the_latest_block_is_not_synced: '最新のブロックが同期できていないため処理できません',
  failed_to_get_latest_block_info_from_nodes:
    '一部またはすべてのノードのブロック情報を確認できません',
  invalid_network_id_specified:
    'ブロックチェーンのネットワークIDが正しく指定されていないか生成できませんでした',
  invalid_chain_id_specified: 'ブロックチェーンのチェーンID指定が正しくありません',
  invalid_gas_price_specified: 'ブロックチェーンの Gas Price 指定が正しくありません',
  too_long_description_length: '説明文は 1000 文字以内で入力してください',
  can_not_get_cluster_removal_status: 'クラスターを削除可能かどうか確認できません',
  can_not_get_node_removal_status: 'ノードを削除可能かどうか確認できません',
  warn_maybe_not_synced_network:
    '過半数のValidatorノードから応答がないか、ブロック状態が最新でないためこの操作が安全であることの確認が取れませんでした。それでも削除しますか？（削除した場合、ネットワーク状態に不整合が生じる場合があります）',
  invalid_uuid_format: '不正な書式のUUIDが指定されています',
  invalid_domain_name_format: '不正な形式のドメイン名が指定されています',
  invalid_cert_arn_format: '不正な形式の証明書ARNが指定されています',
  invalid_account_uuid_format: 'アカウントのUUID書式が正しくありません',
  the_network_is_in_the_process_of_force_deleting: 'ネットワークは強制削除中のため操作できません',
  the_cluster_is_in_the_process_of_force_deleting: 'クラスターは強制削除中のため操作できません',
  the_node_is_in_the_process_of_force_deleting: 'ノードは強制削除中のため操作できません',
  detection_of_stacked_resource_is_disabled:
    '無応答のリソースを検知する機能が無効化されているため削除処理ができません',
  the_network_has_stacked_and_force_deleting_resource:
    'ネットワークまたはその配下のリソースが作成・削除中の無応答による強制削除に入っているため操作できません',
  can_not_change_the_last_signer_to_tx:
    'ネットワークで唯一の Validator につきRelayノードに変更できません',
  display_name_too_long: '表示名が長すぎます',
  invalid_email_format: '無効なメール形式',
  network_name_too_short: 'ネットワーク名が短すぎます',
  network_name_too_long: 'ネットワーク名が長すぎます',
  cluster_name_too_short: 'クラスター名が短すぎます',
  cluster_name_too_long: 'クラスター名が長すぎます',
  number_of_node_too_small: 'ノード数が少なすぎます',
  number_of_node_too_large: 'ノード数が多すぎます',
  network_description_too_long: 'ネットワークの説明が長すぎます',
  node_name_too_short: 'ノード名が短すぎます',
  node_name_too_long: 'ノード名が長すぎます',
  node_size_too_large: 'ノードサイズが大きすぎます',
  can_not_get_expand_info_of_volume: 'データボリュームの拡張情報が見つかりません',
  database_write_conflict:
    'データベース処理が混雑しているため操作できません。しばらくお待ちの上、再度お試しください。',
  only_the_consortium_administrator_can_update_network_option:
    'コンソーシアム管理者のみがネットワークオプションを更新できます。',
  update_network_option_success: 'ネットワークオプションの更新が完了しました。',
  Cannot_change_to_PrimaryOwner_if_change_permission_is_not_owner:
    '変更対象の権限がオーナーではない場合、プライマリーオーナーを譲渡できません',
  // Registration Modal
  howToRegisterAccount: '新規アカウントの登録方法',
  registration_title: '初回登録の方へ ',
  registration_content:
    'はじめてG.U.Blockchain Cloudをお使いの方で、既にGoogleアカウントをお持ちの場合は、 [Login with Google Account]を選択していただいてGoogleアカウント認証いただくことで、初回登録が自動的に行われます。Googleアカウント以外のメールアドレスをご使用になる場合、 [Login with Email Address] ボタンを押していただき、登録したいメールアドレスを入力していただくと、初回登録用に名前とパスワード入力ボックスが出現しますので、そちらから初期登録を行ってください。',
  // Contact Email
  contact_email: '連絡先メールアドレス',
  input_contact_email: '連絡先のメールアドレスを入力',
  // Tab Titles
  nodes_title: 'ノード一覧',
  cluster_overview_title: 'クラスター概要',
  cluster_security_title: 'クラスターセキュリティ',
  cluster_domain_title: 'クラスタードメイン',
  block_explorer_overview_title: 'ブロックエクスプローラ/概要',
  block_explorer_security_title: 'ブロックエクスプローラ/セキュリティ',
  block_explorer_metrics_title: 'ブロックエクスプローラ/統計情報',
  node_overview_title: 'ノード概要',
  node_security_title: 'ノードセキュリティ',
  node_metrics_title: 'ノード統計情報',
  node_logs_title: 'ノードログ',
  blockchain_network_overview: 'ブロックチェーンネットワーク概要',
  member_access: 'メンバー権限',
  can_not_add_custom_domain: 'カスタムドメインの追加に失敗しました。',
  domain: 'ドメイン',
  add_custom_domain: 'ドメイン追加',
  domain_list: 'ドメイン一覧',
  domain_name: 'ドメイン名',
  input_domain_name: 'ドメイン名を入力',
  target_domain: '対象ドメイン',
  add_domain: 'ドメイン追加',
  delete_domain: 'ドメイン削除',
  registration_information: '登録情報',
  please_include_the_information_for_the_custom_domain_you_wish_to_add:
    '追加するカスタムドメイン名を入力してください',
  you_want_to_delete_the_custom_domain: 'カスタムドメインを削除してもよろしいでしょうか？',
  pending_validation: '保留中',
  other: 'その他',
  custom_domain_info: 'カスタムドメイン情報',
  custom_domain_description: 'ドメインを取得したサービスのDNS設定でCNAMEの設定を行ってください。',
  for_authentication: '認証用',
  for_registration: '登録用',
  the_domain_name_is_already_registered: 'ドメイン名が既に登録されています。',
  view: '表示',
  cluster_is_being_created_or_deleted: 'クラスタは作成中または削除中のため利用できません',
  can_not_add_block_explorer: '追加できません Block Explorer',
  can_not_remove_block_explorer: '削除できません Block Explorer',
  block_explorer_not_found_or_not_empty: '見つからない、または空ではない Block Explorer',
  delete_block_explorer: 'クラスター　Block Explorer　削除',
  are_you_sure_you_want_to_delete_this_block_exp:
    'このクラスターのBlock Explorer を削除してもよろしいですか？',
  change_node_version: 'ノード バージョンの更新',
  enable_rebuild_node: 'ノードの再構築を有効にする',
  rebuild_node: 'ノードの再構築',
  are_you_sure_you_want_to_downgrade_this_node:
    'ノードのダウングレードでは、再構築してネットワーク データをリセットし、他のノードから再フェッチする必要があります。',
  are_you_sure_you_want_to_rebuild_this_node:
    'ノードを再構築すると、ネットワーク データがリセットされ、他のノードから再取得されます。\nこのノードを再構築しますか?',
  update_block_exp_started: 'ブロック エクスプローラーの更新を開始しました',
  can_not_update_block_exp: 'ブロック エクスプローラーを更新できません',
  re_captcha_secret_key: 'reCAPTCHA 秘密鍵',
  re_captcha_client_key: 'reCAPTCHA サイトキー',
  update_block_exp: 'ブロック エクスプローラーの更新',
  update_and_rebuild: '更新と再構築',
  two_factor_auth: '２段階認証',
  two_factor_auth_guide:
    '２段階認証をするためには、{{value}}へログインし、「設定」→「セキュリティ」から電話番号の登録を行なってください。',
  rebuild_block_exp: ' 再構築 Block Explorer',
  clean_blockscout_db: ' Blockscout データベースをクリーンアップする',
  are_you_sure_you_want_to_clean_db:
    'Blockscout データベースをクリーンアップすると、データがリセットされ、アーカイブ ノードから再フェッチされます。\nBlockscout データベースをクリーンアップしてもよろしいですか?',
  block_exp_type: 'Block Explorer タイプ',
  change_block_gas_limit: 'Block Gas Limitを変更する',
  update_gas_limit_start: 'Block Gas Limitの更新が開始されました',
  can_not_update_block_gas_limit: 'ブロックガス制限を更新できません',
  change_instance_type: 'インスタンスタイプの変更',
  change_instance_type_started: 'インスタンスタイプの変更が開始されました',
  apply_latest_genesis_config: '最新のジェネシス設定を適用する',
  target_block: 'ターゲットブロック',
  hard_fork: 'ハードフォーク',
  latest_hard_fork: '最新のハードフォーク',
  genesis_json: 'genesis.json',
  genesis_config: '構成Genesis',
  create_hard_fork_proposal: 'ハードフォーク提案の作成',
  create_hard_fork_proposal_successfully: 'ハードフォーク提案を正常に作成する',
  latest_block: '最新ブロック',
  scheduling_hard_fork: 'ハードフォークのスケジュール設定',
  can_not_update_latest_hard_fork: '最新のハードフォークを更新できません',
  update_latest_hard_fork_start: '最新のハードフォーク開始を更新します',
  period_reach_target_block: '承認後の有効期間',
  incompatibility: '非互換性',
  compatibility: '互換性',
  number_of_incompatible_validators: '互換性のないValidatorの数',
  compatible_nodes: '互換性のあるノード',
  many_node_incompatible_geth_version: '互換性のない geth バージョンを持つノードが多すぎます。',
  retry: 'リトライ',
  retry_apply_hard_fork_success: 'ネットワークが成功するまでハード フォークを適用して再試行します',
  retry_apply_hard_fork_fail: 'ネットワークへのハードフォーク適用の再試行に失敗しました',
  hard_fork_status: 'ハードフォークのステータス',
  done: '完了',
  failed: '失敗',
  syncing: '同期中',
  create_hard_fork_node_not_ready_warning:
    'ハードフォークの準備ができていないノードがいくつかあります。ハードフォークを作成してもよろしいですか?',
  allow_unprotected_txs: '保護されていない (EIP155 署名されていない) トランザクションを許可する',
  new_proposal: '新しい提案',
  waiting: '投票待ち',
  next_epoch_block: '次の Epoch Block',
  discard: '破棄',
  be_proposed_by_other_nodes: '他のノードから提案される',
  proposing_other_nodes: '投票状況',
  in_geth_local: 'Gethローカルで',
  in_network: 'ネットワーク内',
  my_node: '自組織アカウントのノード',
  receipt_from: '{{ name }}からの領収書',
  receipt: '領収書番号',
  amount_paid: 'お支払い金額',
  date_paid: 'お支払い日時',
  payment_method: 'お支払い方法',
  summary: 'サマリー',
  receipt_contact: 'ご不明な点がございましたら、{{ email }} へお問い合わせください。',
  receipt_wrong_email: 'メールの内容が適切に表示されない場合は',
  view_browser: 'ブラウザでご覧ください。',
  invoice: '請求書',
  date_of_issue: '発行日',
  bill_to: '請求先',
  qty: '数量',
  amount_due: '料金',
  view_invoice: '請求書の表示',
  view_receipt: '領収書の表示',
  qualified_invoice_issuer_registration_number: '登録番号',
  pre_tax_amount: '税抜金額',
  tax_inclusive_amount: '税込金額',
  business_name: 'G.U.テクノロジーズ株式会社',
  dealing_date: '取引日',
  date_of_issue_at: '{{date, YYYY年MM月DD日}}',
  date_paid_at: '{{date, YYYY年MM月DD日 h:mm:ss A}}',
  invoice_amount: '金額',
  business_postal_code: '〒150-8512',
  business_city: '東京都渋谷区桜丘町26-1',
  business_addr: 'セルリアンタワー15F',
  required_block_gas_limit: 'Block Gas Limit を入力してください',
  go_to_proposal_history: '提案履歴に移動',
  go_to_latest_proposals: '最新の提案に移動',
  waitting_import_new_proposals: '新しい提案のインポートを待っています',
  all_proposals_reset: 'すべての提案がリセットされます',
  block: 'ブロック',
  validator: 'Validator',
  relay: 'Relay',
  normal: '普通',
  filter_end_block_more_than_start_block: 'To Block は Start Block より大きくなければなりません',
  from_block: 'このブロックから',
  to_block: 'このブロックまで',
  address: 'アドレス',
  send_proposal_from: '提案の送信元',
  for_target_node_address: 'ターゲットノードアドレス',
  node_status: 'ノードのステータス',
  network_porposal_status: 'ネットワーク投票状況',
  geth_local_porposal_status: 'Gethローカルの投票状況',
  discard_proposal: 'Gethローカルの投票状況の破棄',
  discard_proposal_content:
    'Gethローカルの投票状況を破棄すると、次のエポック ブロックでネットワーク内の投票が破棄されます。',
  epoch_block: 'Epoch Block',
  input_epoch_block: 'Epoch Block 入力',
  create_proposal: '提案の作成',
  external_transfer_address: '外部転送アドレス',
  connect_to_external_clef: '外部clefに接続する',
  signer_address: '署名者のアドレス',
  input_signer_address: '署名者のアドレスを入力してください',
  clef_server_username: 'ユーザー名',
  input_clef_server_username: 'clefサーバーのユーザー名を入力',
  clef_server_host: 'ホスト名/IPアドレス',
  input_clef_server_host: 'clefサーバーのホスト名/ＩＰアドレスを入力',
  clef_api_port: 'clefのAPIポート',
  input_clef_api_port: 'APIポートを入力',
  external_signer_address: '外部署名者のアドレス',
  external_signer_api_port: '外部署名者 API ポート',
  clef: '音部記号',
  update_external_clef: '外部clefの更新',
  x_warn_not_connected_to_external_clef: '外部clefに接続されていません',
  connected: '接続済み',
  connecting: '接続中 ...',
  not_connected: '接続されていません',
  invalid_external_server_host: '無効なホスト名',
  can_not_update_external_clef: '外部音部記号を更新できません',
  update_external_clef_success: '外部clefを正常に更新しました',
  external_signer_server: '外部署名者サーバー',
  ssh_private_key_file: 'SSHプライベートキーファイル',
  ssh_private_key_too_large: 'プライベートキーのファイルが大きすぎます。',
  invalid_ssh_private_key: '無効なSSHプライベートキー',
  available_when_create_one_node: '1 つのノードを作成する場合にのみ使用可能',
  invalid_supported_card_number: 'このJCBカードはサポートされていません',
  small_v1: 'Small (v1)',
  medium_v1: 'Medium (v1)',
  large_v1: 'Large (v1)',
  xlarge_v1: 'XLarge (v1)',
  '2xlarge_v1': '2XLarge (v1)',
  small_v2: 'Small (v2)',
  medium_v2: 'Medium (v2)',
  large_v2: 'Large (v2)',
  business_v2: 'Business (v2)',
  businesslarge_v2: 'Business Large (v2)',
  mega_v2: 'Mega (v2)',
  metal_v2: 'Metal (Max TPS) (v2)',
  small_v3: 'Small (v3)',
  medium_v3: 'Medium (v3)',
  large_v3: 'Large (v3)',
  business_v3: 'Business (v3)',
  businesslarge_v3: 'Business Large (v3)',
  mega_v3: 'Mega (v3)',
  metal_v3: 'Metal (Max TPS) (v3)',
  block_explorer_version: 'Block Explorer バージョン',
  inactive_user_warning:
    'このメンバーはロックされている可能性があります。または、問題が発生しています。管理者に連絡してください。',
  unknown_member: '不明なメンバー',
  wallet_connect_project_id: 'ウォレット接続プロジェクトID',
  upgrade_block_explorer_version: 'ブロックエクスプローラーバージョンをアップグレード',
  enable_frontend: 'Blockscout UI V2を有効化',
  network_RPC_endpoint: 'ネットワークRPCエンドポイント',
  select_block_exp_backend_version: 'バックエンドバージョンを選択',
  select_block_exp_frontend_version: 'フロントエンドバージョンを選択',
  input_wallet_connect_project_id: 'ウォレット接続プロジェクトIDを入力',
  input_network_RPC_endpoint: 'ネットワークRPCエンドポイントを入力',
  input_re_captcha_secret_key: 'reCAPTCHA秘密鍵を入力',
  input_re_captcha_client_key: 'reCAPTCHAクライアントキーを入力',
  network_RPC_endpoint_description:
    'ネットワークのRPCエンドポイントです。これはブロックエクスプローラーでネットワークに接続するために使用されます。ポートが開いていることを確認してください。ブロックエクスプローラーを更新して他のネットワークRPCエンドポイントを使用することもできます。',
  input_network_RPC_endpoint_description:
    'ネットワークのRPCエンドポイントです。これはネットワークに接続するために使用されます。ポートが開いていることを確認してください。',
  BLOCK_EXPLORER: 'Block Explorer',
  HTTP_RPC: 'HTTP RPC',
  WS_RPC: 'WS RPC',
  edit_listeners: 'リスナーの編集',
  duplicate_listener_rule: '{{ service }} サービスの重複リスナールール',
  exist_many_port_each_service: '{{ service }} サービスには多くのリスナーポートがあります',
  duplicate_listener_port: '重複リスナーポート',
  block_explorer_port_change_warning:
    'Block Explorer のリスナー ポートを変更するには、Block Explorer の再構築が必要になる場合があります。アップデートを続行する準備はできていますか?',
  confirm_update_listeners: 'リスナーの更新を確認する',
  port: 'ポート',
  select_service: 'サービスの選択',
  select_port: 'ポートの選択',
  add_listener: 'リスナーの追加',
  block_explorer_not_specified: 'ブロック・エクスプローラーが指定されていません。',
  validator_node: 'Validator Node',
  relay_node: 'Relay Node',
  num_of_validators: 'Validatorノード数',
  internal_clef_version: '内部Clefバージョン',
  migrate_to_internal_clef: '内部Clefノードへ移行',
  migrate_to_clef_start: 'Clefノードへの移行開始',
  can_not_migrate_to_internal_clef_node: '内部Clefノードに移行できません',
  transfer_token_denied: 'リクエストされた転送トークンは署名者によって拒否されました',
  x_warn_not_connected_to_internal_clef: '内部 Clef に接続されていません',
  x_internal_clef_is_pending: '内部 Clef は保留中です',
  migrate_internal_clef_warning:
    '現在、Geth {{version}} ノードを使用しています。Clef を使用するには、Geth 1.11.6 以上にアップグレードしてください。',
  default_transfer_addr: 'デフォルト転送アドレス',
  input_default_transfer_addr: 'デフォルト転送アドレスを入力',
  update_transfer_address: '転送アドレスを更新',
  update_transfer_address_done: '転送アドレスの更新が完了しました',
  confirm_clef_send_ether:
    'このノードに保存されている{{ value }}を<b>{{ address }}</b>に転送します。<br/>本当に実行してもよろしいですか？',
  transfer_address_not_exist: 'ノードに転送アドレスが存在しません',
  transfer_token_done: '{{ value }} の転送が完了しました',
  confirm_transfer_token: '転送を確認 {{ 値 }}',
  refresh_connection_to_external_clef: 'Clef への SSH 接続を更新',
  refresh_connection_to_external_clef_start: '外部 Clef への SSH 接続の更新を開始しました',
  refresh_connection_to_external_clef_success: '外部 Clef への SSH 接続を正常に更新しました',
};
